import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

const Start = () => {
    const navigate = useNavigate()
  return (
    // CALL TO ACTION
    <Row className="call-to-action">
        <Col className="post" md={{size:6}}>
            <h3 className="cta">Start creating custom event's poster</h3>
            <p className="cta">Get your participants to start marketing your events for you by generating customized posters.</p>
            <button className="navlinks buttonBanner" onClick={()=>navigate(`/signup`)}>Get Started</button>
            
        </Col>
    </Row>
  )
}

export default Start