import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { baseUrl } from '../../../shared/baseUrl';
import axios from 'axios';
import store from '../../../shared/store';
import CheckoutForm from './CheckoutForm';
import { useNavigate } from 'react-router-dom';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const CheckOut = () => {
  const navigate = useNavigate()
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await axios.post(`${baseUrl}payment/create-intent`,
          {
            amount: store.cartTotal,
            currency: 'CAD'
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });

        const { clientSecret } = response.data;
        setClientSecret(clientSecret);
      } catch (error) {
        console.error('Error fetching client secret:', error);
      }
    };

    fetchClientSecret();
  }, []);
  const appearance = {
    theme: store.darkTheme ? 'night' : 'stripe'
  };

  const options = {
    clientSecret,
    appearance
  };
  if (!clientSecret) {
    return (<p>loading...</p>)
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  );
};

export default CheckOut;
