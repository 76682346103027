import React, { useState } from 'react';
import { Spinner, Container, Row, Col } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { validateEmailReset } from './validateEmailReset';
import axios from 'axios';
import { baseUrl } from '../../shared/baseUrl';
import { observer } from 'mobx-react';
import store from '../../shared/store';

const EmailResetPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState(null)
    const handleReset = async (values) => {
        const details = JSON.stringify({
            email: values.email
        });
        setIsLoading(true)
        try {
            const response = await axios.post(`${baseUrl}users/findEmail`, details,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
            setIsLoading(false)
            setResponseMessage({ ok: true, message: "A password reset email has been sent to the provided email address!" })
        } catch (error) {
            setIsLoading(false);
            console.log(error)
            setResponseMessage({ ok: false, message: error.response.data.message ? error.response.data.message : "Internal server error. Please try again." })
        }

    }

    return (
        // Col, FormGroup, Label, Button, Container 
        <Container className='account-section'>
            <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                    <h2 className="signup fw-bold">Provide Email</h2>

                    {responseMessage ? (responseMessage.ok ?
                        (<div className="alert alert-success">
                            <strong>Success!</strong> {responseMessage.message}
                        </div>) :
                        (<div className="alert alert-danger">
                            <strong>Error!</strong> {responseMessage.message}
                        </div>)) : null
                    }
                    <Formik
                        initialValues={{
                            email: ''
                        }}

                        onSubmit={handleReset}
                        validate={validateEmailReset}
                    >
                        <Form>
                            <div>
                                <Field className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} name="email" id="email" placeholder="Email" aria-label="email" aria-required={true} />
                                <ErrorMessage name='email' >
                                    {(msg) => <p className='required'> {msg}</p>}
                                </ErrorMessage>
                            </div>
                            <div>
                                {isLoading && <Spinner color={store.darkTheme ? 'light' : 'dark'}>Loading...</Spinner>}
                                <button type='submit' className="form-button">Submit</button>
                            </div>
                        </Form>


                    </Formik>
                </Col>
            </Row>
        </Container>

    )
}

export default observer(EmailResetPassword);