import React, { useState } from 'react';
import { Spinner, Container, Row, Col } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { validateChange } from './validateChange';
import axios from 'axios';
import { baseUrl } from '../../shared/baseUrl';
import { observer } from 'mobx-react';
import store from '../../shared/store';

const ChangePassword = () => {
    const [isShown, setIsShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState(null)
    const togglePassword = () => {
        setIsShown(!isShown);
    };
    const handleReset = async (values) => {
let element = document.getElementById("top-page")
        const details = JSON.stringify({
            password: values.password,
            newPassword: values.newPassword
        });
        setIsLoading(true)
        try {

            const response = await axios.post(`${baseUrl}users/changePassword`, details,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })
            setIsLoading(false)
            setResponseMessage({ ok: true, message: "You have successfully changed your password!" })
            store.updateUser(response.data.user);
        } catch (error) {
            setIsLoading(false);
            setResponseMessage({ ok: false, message: error.response.data.message ? error.response.data.message : "Internal server error. Please try again." })
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
        }

    }

    return (
        // Col, FormGroup, Label, Button, Container 
        <Container className='account-section'>
            <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                    <h2 className="signup fw-bold" id="top-page">Change Password</h2>

                    {responseMessage ? (responseMessage.ok ?
                        (<div className="alert alert-success">
                            <strong>Success!</strong> {responseMessage.message}
                        </div>) :
                        (<div className="alert alert-danger">
                            <strong>Error!</strong> {responseMessage.message}
                        </div>)) : null
                    }
                    <Formik
                        initialValues={{
                            password: '',
                            newPassword: '',
                            confirmPassword: ''
                        }}

                        onSubmit={handleReset}
                        validate={validateChange}
                    >
                        <Form>
                            <div>
                                <Field className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} name="password" id="password" placeholder="Password" aria-label="Password" type={isShown ? 'text' : 'password'} aria-required={true} />
                                <ErrorMessage name='password' >
                                    {(msg) => <p className='required'> {msg}</p>}
                                </ErrorMessage>
                            </div>

                            <div>
                                <Field className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id='newPassword' name='newPassword' placeholder='New Password' type={isShown ? 'text' : 'password'} aria-label="New password" aria-required={true} />
                                <ErrorMessage name='newPassword' >
                                    {(msg) => <p className='required marginB'> {msg}</p>}
                                </ErrorMessage>
                                <Field className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id='confirmPassword' name='confirmPassword' placeholder='Retype password' type={isShown ? 'text' : 'password'} aria-label="confirm password" aria-required={true} />
                                <div htmlFor="checkBox" className='showpassword'>Show password?
                                    <input
                                        name='checkBox'
                                        id="checkBox"
                                        type="checkbox"
                                        checked={isShown}
                                        onChange={togglePassword}
                                        className="marginL"
                                    />
                                </div>
                            </div>

                            <div>
                                {isLoading && <Spinner color={store.darkTheme ? 'light' : 'dark'}>Loading...</Spinner>}
                                <button type='submit' className="form-button">Submit</button>
                            </div>
                        </Form>


                    </Formik>
                </Col>
            </Row>
        </Container>

    )
}

export default observer(ChangePassword);