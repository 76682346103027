import React, { useState } from 'react';
import { Spinner, Button, Container, Row, Col } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { validateReset } from './validateReset';
import axios from 'axios';
import { baseUrl } from '../../shared/baseUrl';
import { observer } from 'mobx-react';
import store from '../../shared/store';

const ResetPassword = () => {
    const { resetToken } = useParams();
    const navigate = useNavigate();
    const [isShown, setIsShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState(null)
    const togglePassword = () => {
        setIsShown(!isShown);
    };
    const handleReset = async (values) => {
        const details = JSON.stringify({
            password: values.password
        });
        setIsLoading(true)
        try {
            const response = await axios.post(`${baseUrl}users/resetPassword/${resetToken}`, details,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
            setIsLoading(false)
            setResponseMessage({ ok: true, message: "You have successfully reset your password!" })
        } catch (error) {
            setIsLoading(false);
            console.log(error)
            setResponseMessage({ ok: false, message: error.response.data.message ? error.response.data.message : "Internal server error. Please try again." })
        }

    }

    return (
  
        <Container className='account-section'>
            <Row>
                <Col lg={{ size: 6, offset: 3 }}>

                    <h2 className="signup fw-bold">Reset Password</h2>

                    {responseMessage ? (responseMessage.ok ?
                        (<>
                            <div className="alert alert-success">
                                <strong>Success!</strong> {responseMessage.message}
                            </div>
                            <Button onClick={() => { navigate('/signin') }} color={store.darkTheme ? "light" : "dark"}> Login</Button>
                        </>) :
                        (<div className="alert alert-danger">
                            <strong>Error!</strong> {responseMessage.message}
                        </div>)) : null
                    }
                    {(!responseMessage || (responseMessage && !responseMessage.ok)) ? <Formik
                        initialValues={{
                            password: '',
                            confirmPassword: ''
                        }}

                        onSubmit={handleReset}
                        validate={validateReset}
                    >
                        <Form>
                            <div>
                                <Field className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id='password' name='password' placeholder='New Password' type={isShown ? 'text' : 'password'} aria-label="New password" aria-required={true} />
                                <ErrorMessage name='password' >
                                    {(msg) => <p className='required marginB'> {msg}</p>}
                                </ErrorMessage>
                                <Field className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id='confirmPassword' name='confirmPassword' placeholder='Retype password' type={isShown ? 'text' : 'password'} aria-label="confirm password" aria-required={true} />
                                <div htmlFor="checkBox" className='showpassword'>Show password?
                                    <input
                                        name='checkBox'
                                        id="checkBox"
                                        type="checkbox"
                                        checked={isShown}
                                        onChange={togglePassword}
                                        className="marginL"
                                    />
                                </div>
                            </div>

                            <div>
                                {isLoading && <Spinner color={store.darkTheme ? 'light' : 'dark'}>Loading...</Spinner>}
                                <button type='submit' className="form-button">Submit</button>
                            </div>
                        </Form>


                    </Formik> : null}
                </Col>
            </Row>
        </Container>

    )
}

export default observer(ResetPassword);