import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button } from 'reactstrap';
import { selfUrl } from '../../../shared/baseUrl';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const CheckoutForm = () => {
    const [agreedTerms, setAgreedTerms] = useState(false)
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${selfUrl}payment-complete`,
            },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)

            console.log(result.error.message);
        } else {

        }
    };

    return (
        <form onSubmit={handleSubmit} className="payment-form">
        {/* <h2 className='display-4 fw-bold'>Checkout</h2> */}
        <hr className='mb-5'></hr>
            <PaymentElement />
            <div className='mt-3'>
                <input type="checkbox" className="checkbox-agreed" checked={agreedTerms} onChange={() => setAgreedTerms(!agreedTerms)} /> By clicking the below button, you agree to the following <Link to='/terms-and-conditions' target='_blank'>Terms and Conditions</Link>, as well as our <Link to="/privacy-policy" target='_blank'>Privacy Policy</Link>.
            </div>
            <Button disabled={!agreedTerms || !stripe} className='mt-4 py-2 px-4'>Pay Now</Button>
        </form>
    )
};

export default CheckoutForm;