import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { amount } from './baseUrl';

class Store {
    user = null;
    events = [];
    cart = [];
    darkTheme= true;
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'store',
            properties: ['user', 'events', 'cart', 'darkTheme'],
            storage: window.localStorage,
            expireIn: 86400000, // One day in milliseconds
            removeOnExpiration: true,
            expireCallback: () => {
                this.clearData(); // Call a method to clear the expired data
            }
        });
    }

    updateUser(user) {
        this.user = user;
    }
    updateEvents(events) {
        this.events = [...events];
    }
    addItem(item) {
        const existingItem = this.cart.find(
            (cartItem) => cartItem.event === item.event
        );

        if (existingItem) {
            existingItem.quantity += 1;
        } else {
            this.cart.push(item);
        }
    }

    removeItem(itemId) {
        this.cart = this.cart.filter((item) => item.event !== itemId);
    }

    updateQuantity(itemId, newQuantity) {
        const item = this.cart.find((item) => item.event === itemId);
        if (item) {
            item.quantity = newQuantity;
        }
    }
     clearCart(){
        this.cart=[]
     }

    get cartTotal() {
        return this.cart.reduce(
            (total, item) => total + amount * item.quantity,
            0
        );
    }
    toggleTheme(){
        this.darkTheme= !this.darkTheme
    }
    clearData() {
        // Reset the values to their initial state or clear as needed
        this.user = null;
        this.events = [];
        this.cart = []
    }

}

const store = new Store();

export default store;
