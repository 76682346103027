export const validateCreateEventForm = async (values) => {
    const { title, selectCategories, posterPhoto, eventLink, linkText, agreedTerms } = values;
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    const errors = {}


    if (!title) {
        errors.title = 'Required'
    }

    if (eventLink && !eventLink.match(urlPattern)) {
        errors.eventLink = 'Invalid URL format'
    }
    // if (eventLink && !linkText) {
    //     errors.linkText = 'Required'
    // }

    if (!selectCategories || !selectCategories.length) {
        errors.selectCategories = "Required";
    }

    if (!posterPhoto) {
        errors.posterPhoto = 'Required'
    }

    if (!agreedTerms) {
        errors.agreedTerms = 'Required'
    }

    return errors;
}