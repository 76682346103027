export const validateSigninForm = (values) => {
    const { username, password } = values;
    const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    const errors = {};

    if (!username) {
        errors.username = 'Required'
    }
    if (!password) {
        errors.password = 'Required'
    }
    return errors;
}