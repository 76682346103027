import { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom'
import { Table, Button, Row, Container, Card, CardBody, CardHeader } from 'reactstrap'
import store from '../../../shared/store'
import { amount } from '../../../shared/baseUrl'
import CheckOut from './Checkout';

const Cart = () => {
    const navigate = useNavigate();
    const [responseMessage, setResponseMessage] = useState(null)

    const formattedPrice = (amount) => {
        return (amount / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'CAD',
            minimumFractionDigits: 2,
        });
    }

    useEffect(() => {
        if (!store.user) {
            navigate('/signin');
        } 
    }, [])


    return (
        <Container className='full-height'>
            <h2 className='display-3 cart-title fw-bold'>Cart</h2>
            <hr className='my-5'></hr>
            {responseMessage ? (responseMessage.ok ?
                (<div className="alert alert-success">
                    {responseMessage.message}
                </div>) :
                (<div className="alert alert-danger">
                    <strong>Error!</strong> {responseMessage.message}
                </div>)) : null
            }

            <Table striped responsive dark={store.darkTheme ? true : false} className='table-fixed'>
                <thead>
                    <tr>
                        <th>
                            Item
                        </th>
                        <th>
                            Amount
                        </th>
                        <th>
                            Month(s)
                        </th>
                        <th>
                            Subtotal
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>

                    {
                        store.cart ? (
                            <>
                                {
                                    store.cart.map((item, idx) => (
                                        <tr key={idx}>
                                            <th scope="row">
                                                {item.title}
                                            </th>
                                            <td>
                                                {formattedPrice(amount)}
                                            </td>
                                            <td>
                                                <input className={store.darkTheme ? 'cart-input dark-theme' : ' cart-input light-theme'} type='number' min="1" value={item.quantity} onChange={(e) => {
                                                    store.updateQuantity(item.event, e.target.value)
                                                }} />
                                            </td>
                                            <td>
                                                {formattedPrice(amount * item.quantity)}
                                            </td>
                                            <td>
                                                <Button className="btn-danger" onClick={() => {
                                                    store.removeItem(item.event);
                                                }}>X</Button>
                                            </td>
                                        </tr>
                                    ))}
                                <tr>
                                    <th colSpan={3} style={{ textAlign: 'right' }}>Total</th>
                                    <th>{formattedPrice(store.cartTotal)}</th>
                                    <th></th>
                                </tr>
                            </>
                        ) : null
                    }

                </tbody>
            </Table>

            <div className='my-5'>
                <Button outline color={store.darkTheme ? "light" : "dark"} size="lg" className={store.darkTheme ? 'text-light px-4 py-2 nav-btn-dark' : 'text-dark px-4 py-2 nav-btn-light'} onClick={() => navigate(`/create-event-dp`)}>Add Poster</Button>
            </div>


            {(store.cartTotal > 0) ?
                (<Row className="mt-5">
                    <Card className={store.darkTheme ? 'bg-dark text-white' : 'bg-light text-dark'}>
                        <CardHeader>
                            <h3 className='mt-5'>
                                Make Payment
                            </h3>
                        </CardHeader>
                        <CardBody>
                            <CheckOut />
                        </CardBody>
                    </Card>
                </Row>) : null
            }

        </Container>
    )
}

export default observer(Cart)