import React from 'react'
import { Card, CardBody, CardTitle, CardHeader } from 'reactstrap'
import store from '../../../shared/store'
import { useNavigate } from 'react-router-dom'

const Profile = () => {
    const navigate = useNavigate()
    return (
        <div className='container'>
            <div className='col-12 profile-title'>
                <>
                    <Card
                        className="mb-4"
                        color="none"
                        outline
                        style={{
                            width: '100%'
                        }}
                    >
                        <CardHeader>
                            Your Name
                        </CardHeader>
                        <CardBody>
                            <CardTitle tag="h1">
                                {`${store.user.firstName} ${store.user.lastName}`}
                            </CardTitle>

                        </CardBody>
                    </Card>

                    <Card
                        className="my-4"
                        color="none"
                        outline
                        style={{
                            width: '100%'
                        }}
                    >
                        <CardHeader>
                            Your Phone
                        </CardHeader>
                        <CardBody>
                            <CardTitle tag="h1">
                                {store.user.phoneNo}
                            </CardTitle>

                        </CardBody>
                    </Card>


                    <Card
                        className="my-4"
                        color="none"
                        outline
                        style={{
                            width: '100%'
                        }}
                    >
                        <CardHeader>
                            Your Email
                        </CardHeader>
                        <CardBody>
                            <CardTitle tag="h1">
                                {store.user.email}
                            </CardTitle>

                        </CardBody>
                    </Card>


                    <Card
                        className="my-4"
                        color="none"
                        outline
                        style={{
                            width: '100%'
                        }}
                    >
                        <CardHeader>
                            Change your Password
                        </CardHeader>
                        <CardBody>
                            <div className='text-center'>
                                <button className='form-button' onClick={() => navigate('/change-password')}>Change Password</button>
                            </div>
                        </CardBody>
                    </Card>
                </>
            </div>
        </div>
    )
}

export default Profile