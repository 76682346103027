import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { validateSigninForm } from './validateSigninForm';
import { observer } from 'mobx-react';
import axios from 'axios';
import store from '../../shared/store';
import { baseUrl } from '../../shared/baseUrl';
import { Spinner, Container, Row, Col } from 'reactstrap';


const SigninForm = () => {
    const navigate = useNavigate();
    const [isShown, setIsShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState(null)
    const togglePassword = () => {
        setIsShown((isShown) => !isShown);
    };
    const handleLogin = async (values) => {
        let element = document.getElementById("top-page")
        setIsLoading(true)
        try {
            const response = await axios.post(`${baseUrl}users/signin`,
                {
                    username: values.username.toLowerCase(),
                    password: values.password
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
            localStorage.setItem('token', response.data.token);
            setIsLoading(false)
            setResponseMessage({ ok: true, message: response.data.message })
            store.updateUser(response.data.user);
        } catch (error) {
            setIsLoading(false)
            setResponseMessage({ ok: false, message: error.response.data.message ? error.response.data.message : "Invalid username or password" })
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
        }
    }
    useEffect(() => {
        if (store.user) {
            navigate('/my-account');
        }
    }, [store.user])
    return (
        <Container className='account-section'>
            <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                    <h2 className="signup fw-bold" id="top-page">Sign In</h2>
                    {responseMessage ? (responseMessage.ok ?
                        (<div className="alert alert-success text-start">
                            <strong>Success!</strong> {responseMessage.message}
                        </div>) :
                        (<div className="alert alert-danger text-start">
                            <strong>Error!</strong> {responseMessage.message}
                        </div>)) : null
                    }
                    <Formik
                        initialValues={{
                            username: '',
                            password: ''

                        }}
                        onSubmit={handleLogin}
                        validate={validateSigninForm}            >
                        <Form >
                            <div>
                                <Field id='username' name='username' placeholder='Email' className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} aria-label="Email" aria-required={true} />
                                <ErrorMessage name='username' >
                                    {(msg) => <p className='required'> {msg}</p>}
                                </ErrorMessage>
                            </div>
                            <div>
                                <Field id='password' name='password' placeholder='Password' className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} type={isShown ? 'text' : 'password'} aria-label="password" aria-required={true} />
                                <ErrorMessage name='password' >
                                    {(msg) => <p className='required'> {msg}</p>}
                                </ErrorMessage>
                                <div htmlFor="checkBox" className='showpassword'>Show password?
                                    <input
                                        name='checkBox'
                                        id="checkBox"
                                        type="checkbox"
                                        checked={isShown}
                                        onChange={togglePassword}
                                        className="marginL"
                                    />
                                </div>
                            </div>
                            <div>
                                {isLoading && <Spinner color={store.darkTheme ? 'light' : 'dark'}>Loading...</Spinner>}
                                <button type='submit' className="form-button"> Login</button>
                            </div>
                        </Form>

                    </Formik>
                    <p>Forgot your password? <span onClick={() => navigate('/find-email')}>Reset Password</span>.</p>
                    <p>If you do not have an account, <span onClick={() => navigate('/signup')}>signup</span>.</p>
                </Col>
            </Row>
        </Container>
    )
}

export default observer(SigninForm);