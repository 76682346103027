import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        //  FOOTER 
        <footer>
            <div className="footer">
                <div className="footer-top">
                    <div>
                        <div className="footer-links">
                            <Link className="foot-logo logo navlinks" to='/'>Postlify</Link>
                        </div>
                        <div className="footer-links mb-4">
                            <Link className="foot-links" to='/pricing'>Pricing</Link>
                            <Link className="foot-links" to='/contact'>Contact</Link>
                            <Link className="foot-links" to='/how-it-works'>How it Works</Link>
                        </div>
                        <div className="footer-links">
                            <Link className="foot-links" to='/privacy-policy'>Privacy Policy</Link>
                            <Link className="foot-links" to='/terms-and-conditions'>Terms & Conditions</Link>

                        </div>
                    </div>


                </div>
                <div className="footer-bottom">
                    <small className='mb-4'>©{year} cymworx.com, All rights reserved.</small>
                    <small>Created by <a href="https://cymworx.com" target="_blank" className='dark-theme'>Cymworx Inc.</a></small>
                </div>

            </div>
        </footer>
    )
}

export default Footer