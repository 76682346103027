import React, { useEffect, useState } from 'react';
import { Spinner, Container, Row, Col } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { validateSignupForm } from './validateSignupForm';
import axios from 'axios';
import { baseUrl } from '../../shared/baseUrl';
import { observer } from 'mobx-react';
import store from '../../shared/store';

const SignupForm = () => {
    const navigate = useNavigate();
    const [isShown, setIsShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState(null)
    const togglePassword = () => {
        setIsShown(!isShown);
    };
    const handleSignup = async (values) => {
        let element = document.getElementById("top-page")
        const details = JSON.stringify({
            username: values.email.toLowerCase(),
            email: values.email.toLowerCase(),
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNo: values.phoneNo
        });
        setIsLoading(true)
        try {
            let emailNotUnique
            const response = await axios.post(`${baseUrl}users/checkUniqueUsername`,
                {
                    username: values.email.toLowerCase()
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
            emailNotUnique = response.data.foundUser;
            if (emailNotUnique) {
                setIsLoading(false);
                setResponseMessage({ ok: false, message: "Email has been previously registered" })
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
                return;
            }
            try {
                const response = await axios.post(`${baseUrl}users/signup`, details,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                localStorage.setItem('token', response.data.token);
                setIsLoading(false)
                setResponseMessage({ ok: true, message: "You have been successfully registered!" })
                store.updateUser(response.data.user);
            } catch (error) {
                setIsLoading(false);
                // console.log("inner")
                setResponseMessage({ ok: false, message: "Internal server error. Please try again." })
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        } catch (error) {
            setIsLoading(false);
            // console.log("outer")
            setResponseMessage({ ok: false, message: "Internal server error. Please try again." })
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

    }

    useEffect(() => {
        if (store.user) {
            navigate('/my-account');
        }
    }, [store.user])
    return (
        <Container className='account-section'>
            <Row>
                <Col lg={{ size: 6, offset: 3 }}>

                    <h2 className="signup fw-bold" id="top-page">Sign Up</h2>

                    {responseMessage ? (responseMessage.ok ?
                        (<div className="alert alert-success">
                            <strong>Success!</strong> {responseMessage.message}
                        </div>) :
                        (<div className="alert alert-danger">
                            <strong>Error!</strong> {responseMessage.message}
                        </div>)) : null
                    }
                    <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            email: '',
                            phoneNo: '',
                            password: '',
                            confirmPassword: '',
                            agreedTerms: false
                        }}

                        onSubmit={handleSignup}
                        validate={validateSignupForm}
                    >
                        {({ values }) => (
                            <Form>

                                <Field className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} name='firstName' placeholder='First Name' aria-label="first name" aria-required={true} />
                                <ErrorMessage name='firstName' >
                                    {(msg) => <p className='required'> {msg}</p>}
                                </ErrorMessage>

                                <div>
                                    <Field className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id='lastName' name='lastName' placeholder='Last Name' aria-label="last name" aria-required={true} />
                                    <ErrorMessage name='lastName' >
                                        {(msg) => <p className='required'> {msg}</p>}
                                    </ErrorMessage>
                                </div>

                                <div>
                                    <Field className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id='email' name='email' type="email" placeholder='Email' data-testid="signup-component" aria-label="email" aria-required={true} />
                                    <ErrorMessage name='email' >
                                        {(msg) => <p className='required'> {msg}</p>}
                                    </ErrorMessage>
                                </div>
                                <div>
                                    <Field className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} name="phoneNo" id="phoneNo" placeholder="Phone Number" aria-label="Phone Number" aria-required={true} />
                                    <ErrorMessage name='phoneNo' >
                                        {(msg) => <p className='required'> {msg}</p>}
                                    </ErrorMessage>
                                </div>

                                <div>
                                    <Field className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id='password' name='password' placeholder='Password' type={isShown ? 'text' : 'password'} aria-label="password" aria-required={true} />
                                    <ErrorMessage name='password' >
                                        {(msg) => <p className='required marginB'> {msg}</p>}
                                    </ErrorMessage>
                                    <Field className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id='confirmPassword' name='confirmPassword' placeholder='Retype password' type={isShown ? 'text' : 'password'} aria-label="confirm password" aria-required={true} />
                                    <div htmlFor="checkBox" className='showpassword'>Show password? <input
                                        name='checkBox'
                                        id="checkBox"
                                        type="checkbox"
                                        checked={isShown}
                                        onChange={togglePassword}
                                        className="marginL"
                                    />
                                    </div>
                                </div>

                                <div className='mt-3'>
                                    <Field type="checkbox" name="agreedTerms" className="checkbox-agreed" /> By clicking the below button, you agree to the following <Link to='/terms-and-conditions' target='_blank'>Terms and Conditions</Link>, as well as our <Link to="/privacy-policy" target='_blank'>Privacy Policy</Link>.
                                    <ErrorMessage name='agreedTerms' >
                                        {(msg) => <p className='required'> {msg}</p>}
                                    </ErrorMessage>
                                </div>

                                <div>
                                    {isLoading && <Spinner color={store.darkTheme ? 'light' : 'dark'}>Loading...</Spinner>}
                                    <button type='submit' className="form-button">Sign Up</button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                    <p>If you already have an account, <span onClick={() => navigate('/signin')}>login</span>.</p>
                </Col>
            </Row>
        </Container>

    )
}

export default observer(SignupForm);