import React, { useEffect, useState, useRef } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { baseUrl } from '../../../shared/baseUrl'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import { validateEditEventForm } from './validateEditEventForm'
import CutOut from './CutOut'
import Coordinates from './Coordinates'
import store from '../../../shared/store'

const EditEvent = () => {
  const CurrentDate = new Date();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const divRef = useRef(null);
  const [slugEnabled, setSlugEnabled] = useState(false)
  const [eventDetails, setEventDetails] = useState(null)
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [responseMessage, setResponseMessage] = useState(null)
  const fileRef = useRef();


  useEffect(() => {
    async function getEventDetails() {
      try {
        const response = await axios.get(`${baseUrl}events/${eventId}`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          })
        // console.log(response.data)
        setEventDetails(response.data)
      } catch (error) {
        return Promise.reject('Unable to fetch, status: ');
      }
    }
    getEventDetails();
  }, [])

  const checkSlugUniqueness = async (values, element) => {
    try {
      let slugNotUnique;
      const response = await axios.post(`${baseUrl}events/new/checkUniqueSlug/${eventId}`,
        {
          slug: values.slug
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        })
      slugNotUnique = response.data.foundSlug;
      return slugNotUnique
    }
    catch (error) {
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      setIsLoading(false);
      setResponseMessage({ ok: false, message: "Internal server error. Please try again." })
    }
  }

  const handleSubmitUpdate = async (values, setSubmitting) => {
    setIsLoading(true);
    let element = document.getElementById('top-page');

    if (slugEnabled) {
      const slugNotUnique = await checkSlugUniqueness(values, element)
      if (slugNotUnique) {
        setIsLoading(false);
        setResponseMessage({ ok: false, message: "Slug has been registered for another event." })
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
        setSubmitting(false)
        return;
      }
    }
    var formdata = new FormData();
    formdata.append("title", values.title)
    formdata.append("excerpt", values.excerpt)
    if (slugEnabled) {
      formdata.append("slug", values.slug)
    }
    formdata.append("eventLink", values.eventLink)
    formdata.append("linkText", values.linkText)
    formdata.append("rsvp", values.rsvp)
    formdata.append("top", values.top)
    formdata.append("left", values.left)
    formdata.append("width", values.width)
    formdata.append("height", values.height)
    formdata.append("borderRadius", values.borderRadius)
    formdata.append("contHeight", values.contHeight)
    formdata.append("contWidth", values.contWidth)
    if (values.posterPhoto) {
      formdata.append("posterUrl", values.posterPhoto)
    }

    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    };

    try {
      const response = await axios.put(`${baseUrl}events/${eventId}`, formdata, { headers })
      setIsLoading(false)
      setEventDetails(response.data)
      setResponseMessage({ ok: true, message: "Your poster has been updated!" })
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      setSubmitting(false)
    } catch (error) {
      setIsLoading(false);
      setResponseMessage({ ok: false, message: "Internal server error. Please try again." })
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      setSubmitting(false)
    }

    setSubmitting(false)
  }

  const handleSubmitCheckout = async (values, setSubmitting) => {
    setIsLoading(true);
    const element = document.getElementById('top-page');
    if (slugEnabled) {
      const slugNotUnique = await checkSlugUniqueness(values, element)
      if (slugNotUnique) {
        setIsLoading(false);
        setResponseMessage({ ok: false, message: "Slug has been registered for another event." })
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
        setSubmitting(false)
        return;
      }
    }
    var formdata = new FormData();
    formdata.append("title", values.title)
    formdata.append("excerpt", values.excerpt)
    if (slugEnabled) {
      formdata.append("slug", values.slug)
    }
    formdata.append("eventLink", values.eventLink)
    formdata.append("linkText", values.linkText)
    formdata.append("rsvp", values.rsvp)
    formdata.append("top", values.top)
    formdata.append("left", values.left)
    formdata.append("width", values.width)
    formdata.append("height", values.height)
    formdata.append("borderRadius", values.borderRadius)
    formdata.append("contHeight", values.contHeight)
    formdata.append("contWidth", values.contWidth)
    if (values.posterPhoto) {
      formdata.append("posterUrl", values.posterPhoto)
    }

    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    };
    try {
      const response = await axios.put(`${baseUrl}events/${eventId}`, formdata, { headers })
      setIsLoading(false)
      setEventDetails(response.data)
      setResponseMessage({ ok: true, message: "Your poster has been updated!" })
      setSubmitting(false)
      store.addItem({ event: eventId, quantity: 1, title: values.title })
      navigate('/cart')
    } catch (error) {
      setIsLoading(false);
      setResponseMessage({ ok: false, message: "Internal server error. Please try again." })
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      setSubmitting(false)
    }
  }

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  if (!store.user) {
    return navigate('/signin')
  }
  if (!eventDetails) {
    return (<p>Loading...</p>)
  }
  return (
    <Container className='full-height'>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h2 className="signup fw-bold" id="top-page">Review Custom Poster</h2>
          <hr className='my-5'></hr>
          {responseMessage ? (responseMessage.ok ?
            (<div className="alert-success">
              <strong>Success!</strong> {responseMessage.message}
            </div>) :
            (<div className="alert-danger">
              <strong>Error!</strong> {responseMessage.message}
            </div>)) : null
          }


          <Formik
            initialValues={{
              title: eventDetails.title,
              excerpt: eventDetails.excerpt,
              slug: eventDetails.slug,
              eventLink: eventDetails.eventLink,
              linkText: eventDetails.linkText,
              rsvp: eventDetails.rsvp,
              top: eventDetails.top,
              left: eventDetails.left,
              width: eventDetails.width,
              height: eventDetails.height,
              borderRadius: eventDetails.borderRadius,
              posterPhoto: null,
              contWidth: eventDetails.contWidth,
              contHeight: eventDetails.contHeight
            }}
            onSubmit={(values, setSubmitting) => {
              handleSubmitUpdate(values, setSubmitting)
            }}
            validate={validateEditEventForm}
          >
            {({ values, setFieldValue, handleChange, isSubmitting, setSubmitting, isValid }) => (
              <Form>
                <div>
                  <div>
                    <div>
                      <div htmlFor="title" className='lbl-title'>Event Title</div>
                      <Field name="title" className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id="title" aria-describedby="event title" placeholder="Event Title" />
                      <ErrorMessage name='title'>
                        {(msg) => <p className='required'>{msg}</p>}
                      </ErrorMessage>
                    </div>

                    <div htmlFor="checkBox" className={store.darkTheme ? 'my-3 form-text dark-theme' : 'my-3 form-text light-theme'}>Enable manual creation of URL slug?
                      <input
                        name='checkBox'
                        id="checkBox"
                        type="checkbox"
                        className='ml-1'
                        checked={slugEnabled}
                        onChange={() => setSlugEnabled(!slugEnabled)}
                      />
                    </div>

                    {slugEnabled ? <div>
                      <div htmlFor="slug" className='lbl-title'>URL Slug</div>
                      <Field name="slug" className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id="slug" aria-describedby="Slug" placeholder={eventDetails.slug} />
                      <ErrorMessage name='slug'>
                        {(msg) => <p className='required'>{msg}</p>}
                      </ErrorMessage>
                    </div> : null}

                    <div>
                      <div htmlFor="excerpt" className='lbl-title'>Short Description / Tagline</div>
                      <Field as="textarea" className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id="excerpt" name="excerpt" rows="3" placeholder='Description' />
                      <ErrorMessage name='excerpt'>
                        {(msg) => <p className='required'>{msg}</p>}
                      </ErrorMessage>
                    </div>
                    <div>
                      <div htmlFor="eventLink" className='lbl-title'>Event Registration Link (optional)</div>
                      <Field name="eventLink" className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id="eventLink" placeholder="Event Registration Link" />
                      <ErrorMessage name='eventLink'>
                        {(msg) => <p className='required'>{msg}</p>}
                      </ErrorMessage>


                    </div>
                    <Field name="linkText" className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id="linkText" placeholder="Link Text" />
                    <div>
                      <div htmlFor="rsvp" className='lbl-title'>RSVP Info (optional)</div>
                      <Field name="rsvp" className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id="rsvp" placeholder="RSVP Info" />
                    </div>
                    <div>
                      <div htmlFor="posterPhoto" className='lbl-title'>Poster Photo</div>
                      <input
                        name="posterPhoto"
                        type="file"
                        className={store.darkTheme ? 'input dark-theme' : 'input light-theme'}
                        ref={fileRef}
                        id="posterPhoto"
                        onChange={(e) => {
                          setFieldValue("posterPhoto", e.target.files[0])                          
                        }}
                      />
                      <ErrorMessage name='posterPhoto'>
                        {(msg) => <p className='required'>{msg}</p>}
                      </ErrorMessage>
                    </div>

                  </div>
                  <div>
                    {(values.posterPhoto || eventDetails.posterUrl) && (
                      <div ref={divRef} style={{ border: '1px solid black', position: 'relative', width: '100%' }}>
                        <img src={values.posterPhoto ? URL.createObjectURL(values.posterPhoto) : eventDetails.posterUrl}
                          alt="uploaded poster"
                          width='100%'
                          height="auto"
                          onLoad={handleImageLoad}
                        />
                        <CutOut />
                      </div>
                    )}
                  </div>
                  <br /><br />
                  <Coordinates imageLoaded={imageLoaded} divRef={divRef}/>
                </div>
                <div>
                  {isLoading && <i className='fa fa-spinner fa-pulse fa-lg fa-fw text-primary mx-3' />}
                  {(CurrentDate <= new Date(eventDetails.endDate)) ?
                    <button
                      type="button"
                      className="form-button"
                      onClick={() =>
                        handleSubmitUpdate(values, setSubmitting)
                      }

                      disabled={!isValid || isSubmitting}
                    >Update</button> :
                    <button
                      type="button"
                      className="form-button"
                      onClick={() =>
                        handleSubmitCheckout(values, setSubmitting)
                      }
                      disabled={!isValid || isSubmitting}
                    >Update & Checkout</button>
                  }
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container >
  )
}

export default EditEvent