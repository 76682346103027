import React from 'react'
import { useNavigate } from 'react-router-dom';

const Banner = () => {
    const navigate = useNavigate()
    return (
        // HERO SECTION
        <section className="home-hero-section">
            <h1 className="h1-main">Create customized events <span className="logo h1-main">posters.</span></h1>
            <h3 className='h3-main'>Spread the <span className="blurry"><span>word!</span></span></h3>
            <button className="navlinks buttonBanner" onClick={()=>navigate('/signup')}>Sign Up</button>
        </section>
    )
}

export default Banner;

