export const validateEditEventForm = async (values) => {
    const { title, eventLink, linkText, slug } = values;
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    const slugPattern = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
    const errors = {}


    if (!title) {
        errors.title = 'Required'
    }
    if (eventLink && !eventLink.match(urlPattern)) {
        errors.eventLink = 'Invalid URL format'
    }

    if (!slug) {
        errors.slug = 'Required'
    }else if (!slug.match(slugPattern)) {
        errors.slug = 'Invalid URL slug'
    }
    // if (eventLink && !linkText) {
    //     errors.linkText = 'Required'
    // }

    return errors;
}