import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { Table, Button, Container } from 'reactstrap';
import axios from 'axios';
import store from '../../../shared/store';
import { endUserUrl, baseUrl } from '../../../shared/baseUrl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCopy } from '@fortawesome/free-solid-svg-icons'

const PaymentComplete = () => {
    const [message, setMessage] = useState('')
    const [purchasedPosters, setPurchasedPosters] = useState([])
    const [copySuccess, setCopySuccess] = useState(false);
    const navigate = useNavigate()
    const currentDate = new Date();
    useEffect(() => {
        const params = queryString.parse(window.location.search);
        const paymentIntent = params.payment_intent;
        const clientSecret = params.payment_intent_client_secret;
        const redirectStatus = params.redirect_status;

        async function setOrderLines() {
            try {

                const response = await axios.post(`${baseUrl}payment/record-order-lines`,
                    {
                        orderLines: [...store.cart],
                        totalAmount: store.cartTotal,
                        paymentId: paymentIntent
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });

                store.clearCart()
                console.log(response.data);
                setPurchasedPosters(response.data)

                setMessage('succeeded')

            } catch (error) {

                store.clearCart()
                setMessage('Failed to record order lines')
            }
        }

        if (redirectStatus === 'succeeded') {
            setOrderLines()
        } else {
            setMessage('Failed to complete payment')
        }

    }, []);

    if (message !== 'succeeded') {
        return (<p>{message}</p>)
    }
    return (
        <Container className='full-height'>
            <h4 className='payment-status fw-bold'>Payment Successful</h4>
            <hr className='my-5'></hr>
            <div className='payment-icon'>
                <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#38dd22", backgroundColor: '#fff', borderRadius: '50%', fontSize: '5rem' }} />
            </div>
            <Table striped responsive dark={store.darkTheme ? true : false}>
                <thead>
                    <tr>
                        <th>
                            Event Title
                        </th>
                        <th>
                            Public URL
                        </th>
                        <th>
                            Status
                        </th>
                        <th>
                            Active Until
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        purchasedPosters.map(event => (
                            <tr key={event._id}>
                                <th scope="row">
                                    {event.title}
                                </th>
                                <td>
                                <a href={`${endUserUrl}${event.slug}`} className={store.darkTheme ? "text-decoration-none dark-theme" : "text-decoration-none light-theme"} target="_blank">{`${endUserUrl}${event.slug}`}</a>
                                    <FontAwesomeIcon
                                        icon={faCopy}
                                        className="ms-2"
                                        onClick={() => {
                                            navigator.clipboard.writeText(`${endUserUrl}${event.slug}`).then(() => {
                                                setCopySuccess(true);
                                                setTimeout(() => setCopySuccess(false), 2000); 
                                            })
                                        }}
                                    />
                                    {copySuccess && <span style={{ marginLeft: '5px', color: 'green' }}>Copied!</span>}
                                </td>
                                <td>
                                    {(currentDate > event.endDate) ? 'Inactive' : 'Active'}
                                </td>
                                <td>
                                    {new Date(event.endDate).toLocaleDateString()}
                                </td>
                                <td>
                                    <Button onClick={() => navigate(`/events/${event._id}`)}>
                                        Edit Poster
                                    </Button>
                                </td>
                            </tr>
                        ))
                    }

                </tbody>
            </Table>
        </Container>
    )
}

export default PaymentComplete