import React, { useEffect } from 'react';
import Draggable from 'react-draggable';
import { useFormikContext } from 'formik';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

const CutOut = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {

  })

  const handleDrag = (e, { deltaX, deltaY }) => {
    const x = values.left + deltaX
    const y = values.top + deltaY
    setFieldValue('left', x);
    setFieldValue('top', y);
  };

  const handleResize = (e, { size }) => {
    // Handle resize logic here if needed
    setFieldValue('width', size.width);
    setFieldValue('height', size.height);

  };

  const draggableOpts = {
    grid: [1, 1],
    scale: 1,
    bounds: 'parent'
  };

  const resizableOpts = {
    resizeHandles: ['nw', 'ne', 'se', 'sw'],
    draggableOpts,
  };

  return (
    <Draggable handle=".handle" onDrag={handleDrag} {...draggableOpts} position={{ x: values.left, y: values.top }}>
      <ResizableBox
        width={values.width}
        height={values.height}
        onResize={handleResize}
        {...resizableOpts}
      >
        <div className="handle" style={{ padding: '10px', width: '100%', height: '100%', borderRadius: `${values.borderRadius}%`, cursor: 'move', border: '1px solid black', position: 'relative', overflow: 'hidden' }}>
          <div class="cutout-overlay">
            <div class="plus-sign">+</div>
          </div>
        </div>
      </ResizableBox>
    </Draggable>
  );
};

export default CutOut;
