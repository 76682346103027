import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Row } from 'reactstrap';
import axios from 'axios';
import { baseUrl } from '../../shared/baseUrl';


const ConfirmAccount = () => {
    const { confirmationCode } = useParams();
    const navigate = useNavigate();
    const [responseMessage, setResponseMessage] = useState(null)

    useEffect(() => {
        async function emailConfirmation() {
            try {
                const response = await axios.get(`${baseUrl}users/confirm/${confirmationCode}`)
                setResponseMessage({ ok: true, message: "You account has been confirmed!" })
            } catch (error) {
                setResponseMessage({ ok: false, message: error.message })
            }
        }
        emailConfirmation()
    }, [])

    return (
        <Container className='account-section'>
            <Row className='my-5'>
                <h2 className="signup fw-bold">Confirm Account</h2>
                {responseMessage ? (responseMessage.ok ?
                    (<>
                        <div className="alert alert-success">
                            <strong>Success!</strong> {responseMessage.message}
                        </div>
                        <div className='text-center'>
                            <button onClick={() => { navigate('/signin') }} className="form-button"> Login</button>
                        </div>
                    </>) :
                    (<div className="alert alert-danger">
                        <strong>Error!</strong> {responseMessage.message}
                    </div>)) : null
                }
            </Row>
        </Container>
    )
}

export default ConfirmAccount;