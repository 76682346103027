import React from 'react'
import { Container } from 'reactstrap'
import Banner from '../components/home/Banner'
import Steps from '../components/home/Features'
import Start from '../components/home/Start'
import Features from '../components/home/Features'

const Home = () => {
  return (
    <>
      <Banner />
      <Features />
      <Start />
    </>
  )
}

export default Home