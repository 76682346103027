import { Routes, Route } from 'react-router-dom';
// import { Button } from 'reactstrap';
import { observer } from 'mobx-react';
import Footer from './components/Footer';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Pricing from './pages/Pricing';
import EditEvent from './components/dashboard/events/EditEvent';
import CreateEvent from './components/dashboard/events/CreateEvent';
import Cart from './components/dashboard/events/Cart';
import PaymentComplete from './components/dashboard/events/PaymentComplete';
import '@fortawesome/react-fontawesome'
import './App.css';
import SignupForm from './components/account/SignupForm';
import SigninForm from './components/account/SigninForm';
import MyAccount from './components/dashboard/events/MyAccount';
import ConfirmAccount from './components/account/ConfirmAccount';
import store from './shared/store';
import ChangePassword from './components/account/ChangePassword';
import EmailResetPassword from './components/account/EmailResetPassword';
import ResetPassword from './components/account/ResetPassword';
import Features from './components/home/Features';
import PrivacyPolicy from './pages/Privacy';
import TermsAndConditions from './pages/TermsAndConditions';
import Header from './components/Header';
import ActiveEvents from './components/dashboard/events/ActiveEvents';
import Events from './components/dashboard/events/Events';

function App() {
  return (
    <>
      <Header />
      <main
        style={{
          background: store.darkTheme ? "linear-gradient(0deg, #190303 20%, #010218 80%)" : "var(--color-bg-dashboard)",
          color: store.darkTheme ? "var(--color-light)" : "var(--color-dark)"
        }}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='pricing' element={<Pricing />} />
          <Route path='contact' element={<Contact />} />
          <Route path='signup' element={<SignupForm />} />
          <Route path='signin' element={<SigninForm />} />
          <Route path='active-events' element={<ActiveEvents />} />
          <Route path='events' element={<Events />} />
          <Route path='confirm/:confirmationCode' element={<ConfirmAccount />} />
          <Route path='my-account' element={<MyAccount />} />
          <Route path='change-password' element={<ChangePassword />} />
          <Route path='find-email' element={<EmailResetPassword />} />
          <Route path='how-it-works' element={<Features />} />
          <Route path='reset-password/:resetToken' element={<ResetPassword />} />
          <Route path='cart' element={<Cart />} />
          <Route path='checkout' element={<Cart />} />
          <Route path='payment-complete' element={<PaymentComplete />} />
          <Route path='create-event-dp' element={<CreateEvent />} />
          <Route
            path='events/:eventId'
            element={<EditEvent />}
          />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='terms-and-conditions' element={<TermsAndConditions />} />
        </Routes>      
        
      </main>
      <Footer />
    </>
  );
}

export default observer(App);
