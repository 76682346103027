export const validateSignupForm = async (values) => {
    const { email, password, firstName, lastName, confirmPassword, phoneNo, agreedTerms } = values;
    const emailPattern = /^\w+([.\-_%+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{8,}$/;
    const phoneNoPattern = /^\d{3}-\d{3}-\d{4}$/;
    const errors = {}
   

    if (!firstName) {
        errors.firstName = 'Required'
    }
    if (!lastName) {
        errors.lastName = 'Required'
    }

    if (!email) {
        errors.email = 'Required'
    }else if (!email.match(emailPattern)) {
        errors.email = 'Invalid email address'
    }

    if (!password) {
        errors.password = 'Required'
    } else if (password.length < 8) {
        errors.password = 'Must be at least 8 characters'
    } else if (!password.match(passwordPattern)) {
        errors.password = 'Must contain at least 1 letter, at least 1 number, at least 1 special character and no white spaces'
    } else if (password !== confirmPassword) {
        errors.password = 'Paswords do not match'
    }
    if (!phoneNo) {
        errors.phoneNo = 'Required'
    }
    if (!agreedTerms) {
        errors.agreedTerms = 'Required'
    }
    return errors;
}