import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { baseUrl } from '../../../shared/baseUrl'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import { validateCreateEventForm } from './validateCreateEventForm'
import CutOut from './CutOut'
import Coordinates from './Coordinates'
import { useNavigate, Link } from 'react-router-dom'
import store from '../../../shared/store'
import { Container, Row, Col, Button } from 'reactstrap'

const CreateEvent = () => {
    const divRef = useRef(null);
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [imageLoaded, setImageLoaded] = useState(false);
    const [responseMessage, setResponseMessage] = useState(null)
    const [categoriesList, setCategoriesList] = useState([])
    const fileRef = useRef();
    const catRef = useRef();


    useEffect(() => {
        async function getCategories() {
            try {
                const response = await axios.get(`${baseUrl}categories`)
                setCategoriesList(response.data)
            } catch (error) {
                return Promise.reject('Unable to fetch, status: ');
            }
        }
        getCategories();
    }, [])
    const handleSubmitSave = async (values, setSubmitting) => {
        setIsLoading(true);
        let element = document.getElementById("top-page")
        var formdata = new FormData();
        formdata.append("title", values.title)
        values.selectCategories.forEach((category, idx) => {
            formdata.append("category", category.attributes.value.nodeValue)
        });
        formdata.append("excerpt", values.excerpt)
        formdata.append("eventLink", values.eventLink)
        formdata.append("linkText", values.linkText)
        formdata.append("rsvp", values.rsvp)
        formdata.append("top", values.top)
        formdata.append("left", values.left)
        formdata.append("width", values.width)
        formdata.append("height", values.height)
        formdata.append("borderRadius", values.borderRadius)
        formdata.append("contHeight", values.contHeight)
        formdata.append("contWidth", values.contWidth)
        formdata.append("posterUrl", values.posterPhoto)

        // formdata.forEach(x => console.log(x));

        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        };

        try {
            const response = await axios.post(`${baseUrl}events`, formdata, { headers })
            setIsLoading(false)
            setResponseMessage({ ok: true, message: "Your new poster has been successfully registered!" })
            setSubmitting(false)
            setTimeout(() => {
                setResponseMessage(null)
                navigate(`/my-account`);
            }, "1000");

        } catch (error) {
            setIsLoading(false);
            setResponseMessage({ ok: false, message: "Internal server error. Please try again." })
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            setSubmitting(false)
        }
    }

    const handleSubmitCheckout = async (values, setSubmitting) => {
        setIsLoading(true);
        let element = document.getElementById("top-page")
        var formdata = new FormData();
        formdata.append("title", values.title)
        values.selectCategories.forEach((category, idx) => {
            formdata.append("category", category.attributes.value.nodeValue)
        });
        formdata.append("excerpt", values.excerpt)
        formdata.append("eventLink", values.eventLink)
        formdata.append("linkText", values.linkText)
        formdata.append("rsvp", values.rsvp)
        formdata.append("top", values.top)
        formdata.append("left", values.left)
        formdata.append("width", values.width)
        formdata.append("height", values.height)
        formdata.append("borderRadius", values.borderRadius)
        formdata.append("contHeight", values.contHeight)
        formdata.append("contWidth", values.contWidth)
        formdata.append("posterUrl", values.posterPhoto)

        // formdata.forEach(x => console.log(x));

        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        };

        try {
            const response = await axios.post(`${baseUrl}events`, formdata, { headers })
            setIsLoading(false)
            setResponseMessage({ ok: true, message: "Your new poster has been successfully registered!" })
            setSubmitting(false)
            store.addItem({ event: response.data._id, quantity: 1, title: response.data.title })
            navigate('/cart')
        } catch (error) {
            setIsLoading(false);
            setResponseMessage({ ok: false, message: "Internal server error. Please try again." })
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            setSubmitting(false)
        }
    }

    const handleImageLoad = () => {
        setImageLoaded(true);
      };

    if (!store.user) {
        return navigate('/signin')
    }

    return (
        <Container className='full-height'>
            <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                    <h2 className="signup fw-bold" id="top-page">Upload New Poster</h2>
                    <hr className='my-5'></hr>
                    {responseMessage ? (responseMessage.ok ?
                        (<div className="alert-success">
                            <strong>Success!</strong> {responseMessage.message}
                        </div>) :
                        (<div className="alert-danger">
                            <strong>Error!</strong> {responseMessage.message}
                        </div>)) : null
                    }
                    <Formik
                        initialValues={{
                            title: '',
                            selectCategories: null,
                            otherCategory: '',
                            agreedTerms: false,
                            excerpt: '',
                            eventLink: '',
                            linkText: '',
                            rsvp: '',
                            top: 0,
                            left: 0,
                            width: 200,
                            height: 200,
                            borderRadius: 0,
                            posterPhoto: null,
                            contWidth: 0,
                            contHeight: 0
                        }}
                        onSubmit={(values, setSubmitting) => {
                            handleSubmitCheckout(values, setSubmitting)
                        }}
                        validate={validateCreateEventForm}
                    >
                        {({ values, setFieldValue, handleChange, isSubmitting, setSubmitting, isValid }) => (
                            <Form>
                                <div>
                                    <div>
                                        <div>
                                            <label htmlFor="title" className='lbl-title'>Event Title</label>
                                            <Field name="title" className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id="title" aria-describedby="event title" placeholder="Event Title" />
                                            <ErrorMessage name='title'>
                                                {(msg) => <p className='required'>{msg}</p>}
                                            </ErrorMessage>
                                        </div>

                                        <div>
                                            <label htmlFor="selectCategories" className='lbl-title'>Category</label>
                                            <select
                                                name="selectCategories"
                                                className={store.darkTheme ? 'input dark-theme' : 'input light-theme'}
                                                ref={catRef}
                                                onChange={(e) => {
                                                    const options = e.target.selectedOptions;
                                                    let selectedOptions = Array.from(options);
                                                    setFieldValue("selectCategories", selectedOptions)
                                                }}
                                                multiple
                                            >
                                                {categoriesList.map((category, idx) => {
                                                    return (<option value={category._id} key={idx}>{category.title}</option>);
                                                }
                                                )}
                                            </select>
                                            <ErrorMessage name='selectCategories'>
                                                {(msg) => <p className='required'>{msg}</p>}
                                            </ErrorMessage>
                                        </div>
                                        <div>
                                            <label htmlFor="excerpt" className='lbl-title'>Short Description / Tagline</label>
                                            <Field as="textarea" className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id="excerpt" name="excerpt" rows="3" />
                                            <ErrorMessage name='excerpt'>
                                                {(msg) => <p className='required'>{msg}</p>}
                                            </ErrorMessage>
                                        </div>
                                        <div>
                                            <label htmlFor="eventLink" className='lbl-title'>Event Registration Link (Optional)</label>
                                            <Field name="eventLink" className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id="eventLink" placeholder="http://" />
                                            <ErrorMessage name='eventLink'>
                                                {(msg) => <p className='required'>{msg}</p>}
                                            </ErrorMessage>
                                        </div>
                                        <div>
                                            <Field name="linkText" className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id="linkText" placeholder="e.g. Click here" />
                                            <ErrorMessage name='linkText'>
                                                {(msg) => <p className='required'>{msg}</p>}
                                            </ErrorMessage>
                                        </div>

                                        <div>
                                            <label htmlFor="rsvp" className='lbl-title'>RSVP Info (Optional)</label>
                                            <Field name="rsvp" className={store.darkTheme ? 'input dark-theme' : 'input light-theme'} id="rsvp" placeholder="e.g. Call us at..." />
                                        </div>
                                        <div>
                                            <label htmlFor="posterPhoto" className='lbl-title'>Poster Photo</label>
                                            <input
                                                name="posterPhoto"
                                                type="file"
                                                className={store.darkTheme ? 'input dark-theme' : 'input light-theme'}
                                                ref={fileRef}
                                                id="posterPhoto"
                                                onChange={(e) => {
                                                    setFieldValue("posterPhoto", e.target.files[0])
                                                }}
                                            />
                                            <ErrorMessage name='posterPhoto'>
                                                {(msg) => <p className='required'>{msg}</p>}
                                            </ErrorMessage>
                                        </div>
                                    </div>
                                    <div lg={{ size: 7, offset: 1 }} className="mt-3">
                                        {values.posterPhoto && (
                                            <div ref={divRef} style={{ border: '1px solid black', position: 'relative', width: '100%' }}>
                                                <img src={URL.createObjectURL(values.posterPhoto)}
                                                    alt={values.posterPhoto}
                                                    width='100%'
                                                    height="auto"
                                                    onLoad={handleImageLoad}
                                                />
                                                <CutOut />
                                            </div>
                                        )}
                                    </div>
                                    <br /><br />
                                    <Coordinates imageLoaded={imageLoaded} divRef={divRef}/>
                                </div>
                                <div className='mt-3'>
                                    <Field type="checkbox" name="agreedTerms" className="checkbox-agreed" /> By clicking any of the below buttons, you agree to the following <Link to='/terms-and-conditions' target='_blank'>Terms and Conditions</Link>, as well as our <Link to="/privacy-policy" target='_blank'>Privacy Policy</Link>.
                                    <ErrorMessage name='agreedTerms' >
                                        {(msg) => <p className='required'> {msg}</p>}
                                    </ErrorMessage>
                                </div>
                                <div className="text-center my-3">
                                    {isLoading && <i className='fa fa-spinner fa-pulse fa-lg fa-fw text-primary mx-3' />}
                                    <button
                                        type="button"
                                        className="form-button"
                                        onClick={() =>
                                            handleSubmitCheckout(values, setSubmitting)
                                        }
                                        disabled={!isValid || isSubmitting}
                                    >Checkout & Publish</button>
                                    <Button
                                        type="button"
                                        outline
                                        color={store.darkTheme ? "light" : "dark"}
                                        size="lg" className='px-5 py-3 mt-2'
                                        style={{ width: '80%' }}
                                        onClick={() =>
                                            handleSubmitSave(values, setSubmitting)
                                        }
                                        disabled={!isValid || isSubmitting}
                                    >Save for Later</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    )
}
export default CreateEvent