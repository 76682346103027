import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import 'font-awesome/css/font-awesome.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import store from './shared/store';
import ScrollToTop from './ScrollToTop';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </Provider>

);

// reportWebVitals();
