export const validateChange = async (values) => {
    const { password, newPassword, confirmPassword } = values;
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{8,}$/;
    const errors = {}
   
    if (!password) {
        errors.password = 'Required'
    }
    if (!newPassword) {
        errors.newPassword = 'Required'
    } else if (newPassword.length < 8) {
        errors.newPassword = 'Must be at least 8 characters'
    } else if (!newPassword.match(passwordPattern)) {
        errors.newPassword = 'Must contain at least 1 letter, at least 1 number, at least 1 special character and no white spaces'
    } else if (newPassword !== confirmPassword) {
        errors.newPassword = 'Paswords do not match'
    }
    return errors;
}