export const validateEmailReset = async (values) => {
    const { email} = values;    
    const pattern = /^\w+([.\-_%+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
    const errors = {}
   
    if (!email) {
        errors.email = 'Required'
    }else if (!email.match(pattern)) {
        errors.email = 'Invalid email address'
    }
    return errors;
}