import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import store from '../../../shared/store'
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const CurrentDate = new Date();
    const navigate = useNavigate()
    return (
        <>
            <Row>
                <Col xs={12} sm={6} className='mb-3'>
                    <Card onClick={()=>navigate('/events')} style={{cursor:'pointer'}}>
                        <CardBody>
                            <CardTitle tag="h5">
                                Total No of Posters
                            </CardTitle>
                            <h1 className='fw-bold'>{store.events.length}</h1>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={12} sm={6}>
                    <Card onClick={()=>navigate('/active-events')} style={{cursor:'pointer'}}>
                        <CardBody>
                            <CardTitle tag="h5">
                                No of Active Posters
                            </CardTitle>
                            <h1 className='fw-bold'>{store.events.filter(event => (CurrentDate <= new Date(event.endDate))).length}</h1>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Dashboard