import React from 'react'
import { Row, Button, Col, Card, CardText, CardTitle } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import store from '../shared/store'

const Pricing = () => {
  const navigate = useNavigate()
  return (
    <section className={store.darkTheme ? 'dark-theme container pricing' :'light-theme container pricing'}>
      <h2 className='display-3 price-title fw-bold'>Pricing Plans</h2>
      
      <p className='price-title'>Start creating event poster links at affordable prices.</p>

      
      <Row className='price-tag mt-5 light-theme'>
      
        <Col md={{ size: 4, offset: 2 }} className="mb-3">
          <Card body>
            <CardTitle tag="h5" className='fw-bold'>
              Standard
            </CardTitle>
            <CardText className='price-text'>
              <p style={{height: '80px'}}>Generate a customizable poster link with an elegant and visually appealing design.</p>
              
              <h2 className="display-4 d-inline">$49.99</h2><span>/ month / poster</span>
            </CardText>
            <Button onClick={() => navigate('/create-event-dp')}>
              Get Started
            </Button>
          </Card>
        </Col>
        <Col md={4}>
          <Card body>
            <CardTitle tag="h5" className='fw-bold'>
              Premium
            </CardTitle>
            <CardText className='price-text'>
              <p style={{height: '80px'}}>Craft a unique and tailor-made customizable poster link exclusively designed to suit your brand's identity and requirements.</p>
            
              <h2 className="display-4 d-inline">Custom</h2>
            </CardText>
            <Button onClick={() => navigate('/contact')}>
              Contact Us
            </Button>
          </Card>
        </Col>
      </Row>
    </section>

  )
}

export default Pricing