import React from 'react'
import { Container, Row } from 'reactstrap'

const TermsAndConditions = () => {
    return (
        <Container className='full-height text-start'>
            <Row className='m-2'>
                <h2 className='display-4 fw-bold'>Terms & Conditions</h2>
                <hr className='my-4'></hr>
                <p>Last updated: 24th July 2023</p>

                <p>Welcome to Postlify ("we", "us", or "our"). These Terms and Conditions govern your use of our website and the services we provide.
                    By accessing and using our website and services, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you may not access or use our website or services.</p>
                <ul>
                    <li className='my-3'>
                        <h5>Description of Services:</h5>
                        
                        Postlify provides digital services, including customizable posters. These services are delivered electronically and may include but are not limited to page URLs for people to access.
                    </li>

                    <li className='my-3'>
                       <h5>No Refunds Policy:</h5> 
                        
                        All sales of our digital services are final, and no refunds will be issued. Once you purchase our service, you will gain access to the content or services immediately. Given the nature of digital goods and services, refunds will not be provided, except where required by applicable law.
                    </li>
                    <li className='my-3'>
                    <h5>Payment:</h5> 
                        
                        Payment for our digital services must be made at the time of purchase. We accept credit/debit cards, PayPal. You agree to provide accurate and complete payment information.
                    </li>
                    <li className='my-3'>
                        <h5>Intellectual Property:</h5> 
                        
                        All content, materials, and intellectual property provided as part of our digital services, including but not limited to text, images, graphics, logos, videos, and software, are the property of Postlify or its licensors and are protected by copyright and other intellectual property laws. You are granted a limited, non-exclusive, non-transferable license to access and use the digital services for your personal or internal business purposes only. You may not reproduce, distribute, modify, create derivative works, or publicly display any part of our digital services without our prior written consent.
                    </li>
    
                    <li className='my-3'>
                        <h5>User Conduct:</h5> 
                        
                        You agree not to use our website or digital services for any unlawful purpose or in violation of these Terms and Conditions. You must not upload, post, transmit, or otherwise distribute any content that is harmful, offensive, defamatory, or infringes upon the rights of others.
                    </li>
                    <li className='my-3'>
                        <h5>Disclaimer of Warranties:</h5> 
                        
                        Our digital services are provided on an "as is" and "as available" basis without warranties of any kind, whether express or implied. We make no representations or warranties regarding the accuracy, reliability, or completeness of our digital services. You use our digital services at your own risk.
                    </li>
            
                    <li className='my-3'>
                        <h5>Limitation of Liability:</h5> 
                        
                        In no event shall Postlify, its officers, directors, employees, or affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of our digital services.
                    </li>
                    <li className='my-3'>
                        <h5>Indemnification:</h5> 
                        
                        You agree to indemnify and hold Postlify harmless from any claims, losses, liabilities, damages, costs, and expenses (including attorney's fees) arising from your use of our website or digital services, your violation of these Terms and Conditions, or your violation of any rights of another party.
                    </li>
                    <li className='my-3'>
                        <h5>Modification of Terms:</h5> 
                        
                        We may update or modify these Terms and Conditions at any time without prior notice. Any changes will be effective upon posting on our website. Your continued use of our website and services after the changes become effective constitutes your acceptance of the revised Terms and Conditions.
                    </li>
                    <li className='my-3'>
                        <h5>Governing Law and Jurisdiction:</h5> 
                        
                        These Terms and Conditions shall be governed by and construed in accordance with the laws of Alberta, Canada. Any dispute arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of Alberta, Canada.
                    </li>

                </ul>

                <p>If you have any questions or concerns about these Terms and Conditions, please contact us at info@cymworx.com.</p>

                <p>Thank you for using Postlify and enjoying our services.</p>
            </Row>
        </Container>
    )
}

export default TermsAndConditions