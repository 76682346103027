import React from 'react'
import { CardHeader, Card, CardBody, CardTitle, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'


const Contact = () => {
  return (
    <div className='container contact-section'>
      <h2 className='display-3 contact-title text-center fw-bold'>Contact Us</h2>
      <p className='contact-title text-center mb-5'>We would love to hear from you. Reach us through...</p>
      <hr className='my-5'></hr>
      <Row className='pb-5 mx-auto'>
        <Col sm={{size:8, offset:2}}>
          <Card
            className="mb-4"
            color="none"
            outline
            style={{
              width: '100%'
            }}
          >
            <CardHeader className='text-start'>
              Email
            </CardHeader>
            <CardBody>
              <CardTitle tag="h3" className='text-start'>
                info@cymworx.com
              </CardTitle>

            </CardBody>
          </Card>

          <Card
            className="mb-4"
            color="none"
            outline
            style={{
              width: '100%'
            }}
          >
            <CardHeader className='text-start'>
              Phone
            </CardHeader>
            <CardBody>
              <CardTitle tag="h3" className='text-start'>
                (403)-473-6994
              </CardTitle>

            </CardBody>
          </Card>
          <Card
            color="none"
            outline
            style={{
              width: '100%'
            }}
          >
            <CardHeader className='text-start'>
              Socials
            </CardHeader>
            <CardBody>
              <CardTitle tag="h3" className='text-start'>
              <a className='light-theme' href="https://twitter.com/cymworx" target="_blank"><FontAwesomeIcon icon={faTwitter}/></a> | <a className='light-theme' href="https://www.instagram.com/cymworx" target="_blank"><FontAwesomeIcon icon={faInstagram}/></a> | <a className='light-theme' href="https://g.page/cymworx/review?rc" target="_blank"><FontAwesomeIcon icon={faGoogle}/></a>
              </CardTitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Contact