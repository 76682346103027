import { useNavigate } from 'react-router-dom'
import { Table, Button, Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import store from '../../../shared/store'
import { endUserUrl } from '../../../shared/baseUrl'
import { useEffect, useState } from 'react'

const ActiveEvents = () => {
    const [copySuccess, setCopySuccess] = useState(false);
    const navigate = useNavigate();
    const CurrentDate = new Date();

    useEffect(() => {
        if (!store.user) {
            navigate('/signin');
        }
    }, [])
    if(!store.events.length) {
        return(<p>You have not created any event posters.</p>)
    }
    return (
        <Container className='full-height'>
        <h4 className='fw-bold my-4'>Active Posters</h4>
            <Table responsive dark={store.darkTheme ? true : false}>
                <thead>
                    <tr className={store.darkTheme? "table-light" : "table-dark"}>
                        <th>
                            Event Title
                        </th>
                        <th>
                            Public URL
                        </th>
                        <th>
                            Expiry Date
                        </th>
                        <th>
                            Status
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        store.events ? (store.events.filter(e => (CurrentDate <= new Date(e.endDate))).map(event => (
                            <tr key={event._id}>
                                <th scope="row">
                                    {event.title}
                                </th>
                                <td>
                                <a href={`${endUserUrl}${event.slug}`} className={store.darkTheme ? "text-decoration-none dark-theme" : "text-decoration-none light-theme"} target="_blank">{`${endUserUrl}${event.slug}`}</a>
                                    <FontAwesomeIcon
                                        icon={faCopy}
                                        className="ms-2"
                                        onClick={() => {
                                            navigator.clipboard.writeText(`${endUserUrl}${event.slug}`).then(() => {
                                                setCopySuccess(true);
                                                setTimeout(() => setCopySuccess(false), 2000);
                                            })
                                        }}
                                    />
                                    {copySuccess && <span style={{ marginLeft: '5px', color: 'green' }}>Copied!</span>}
                                </td>
                                <td>
                                    {new Date(event.endDate).toLocaleDateString()}
                                </td>
                                <td>
                                    {(CurrentDate > new Date(event.endDate)) ? 'Inactive' : 'Active'}
                                </td>
                                <td>
                                    <Button outline color={store.darkTheme ? "light" : "dark"} onClick={() => navigate(`/events/${event._id}`)}>
                                        Edit Page
                                    </Button>
                                </td>
                            </tr>
                        ))) : null
                    }

                </tbody>
            </Table>
        </Container>
    )
}

export default ActiveEvents