import React, { useState, useEffect } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    Row,
    Col
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon, faSun, faCartShopping } from '@fortawesome/free-solid-svg-icons'
import store from "../shared/store";

function Header(args) {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        if (screenWidth < 768) {
            setIsOpen(!isOpen);
        }
    }

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        store.updateUser(null)
        store.updateEvents([])
        navigate(`/signin`)
    }

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='header fixed-top d-flex align-items-center' style={store.darkTheme ? null : { backgroundColor: 'rgba(255, 255, 255, 0.4)' }}>
            <Row className="p-0 main-nav">
                <Col xs={9} md={11} className=''>
                    <Navbar {...args} dark={store.darkTheme ? true : false} expand="md" container="md">
                        <Link className="logo navlinks navbar-brand" to='/'>Postlify </Link>
                        <NavbarToggler onClick={toggle} />
                        <Collapse isOpen={isOpen} navbar>
                            <Nav className="me-auto" navbar>
                                <NavItem>
                                    <Link onClick={toggle} className={store.darkTheme ? "nav-link dark-theme" : "nav-link light-theme"} aria-current="page" to='/pricing'>Pricing</Link>
                                </NavItem>
                                <NavItem>
                                    <Link onClick={toggle} className={store.darkTheme ? "nav-link dark-theme" : "nav-link light-theme"} to='/contact'>Contact</Link>
                                </NavItem>
                                <NavItem>
                                    <Link onClick={toggle} className={store.darkTheme ? "nav-link dark-theme" : "nav-link light-theme"} aria-disabled="true" to='/how-it-works'>How it Works</Link>
                                </NavItem>

                            </Nav>
                            <Nav className="ms-auto" navbar>
                                {!store.user ?
                                    (<>
                                        <NavItem>
                                            <Link onClick={toggle} className={store.darkTheme ? "nav-link dark-theme" : "nav-link light-theme"} to='/signin'>Sign In</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link onClick={toggle} className={store.darkTheme ? "btn btn-outline-light text-light nav-btn-dark" : "btn btn-outline-dark text-dark nav-btn-light"} to='/signup'>Sign Up</Link>
                                        </NavItem>
                                    </>) :
                                    (<>
                                        <NavItem>
                                            <Link onClick={toggle} className={store.darkTheme ? "nav-link dark-theme" : "nav-link light-theme"} to='/my-account'>My Account</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link className={store.darkTheme ? "btn btn-outline-light text-light nav-btn-dark" : "btn btn-outline-dark text-dark nav-btn-light"} onClick={() => {
                                                handleLogout();
                                                toggle();
                                            }}>Sign Out</Link>
                                        </NavItem>

                                    </>)
                                }

                            </Nav>

                        </Collapse>

                    </Navbar>
                </Col>
                <Col xs={3} md={1} className='sec-nav'>
                    <div>
                        <span onClick={() => navigate('/cart')}><FontAwesomeIcon icon={faCartShopping} size='md' className={store.darkTheme ? 'dark-theme me-3' : "light-theme me-3"} /></span>


                        <span onClick={() => store.toggleTheme()}>
                            {store.darkTheme ?
                                <FontAwesomeIcon icon={faSun} size='md' style={{ color: 'var(--color-light)' }} /> :
                                <FontAwesomeIcon icon={faMoon} size='md' style={{ color: 'var(--color-dark)' }} />
                            }
                        </span>
                    </div>

                </Col>
            </Row>
        </div>


    );
}

export default Header;