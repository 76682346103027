export const validateReset= async (values) => {
    const { password, confirmPassword } = values;
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{8,}$/;
    const errors = {}


    if (!password) {
        errors.password = 'Required'
    } else if (password.length < 8) {
        errors.password = 'Must be at least 8 characters'
    } else if (!password.match(passwordPattern)) {
        errors.password = 'Must contain at least 1 letter, at least 1 number, at least 1 special character and no white spaces'
    } else if (password !== confirmPassword) {
        errors.password = 'Paswords do not match'
    }

    return errors;
}