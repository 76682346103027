import React, { useState, useEffect } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane, Col, Row, Button } from "reactstrap"
import Media from 'react-media'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Dashboard from './Dashboard'
import Events from './Events'
import Profile from './Profile'
import store from '../../../shared/store'
import { baseUrl } from '../../../shared/baseUrl'

const MyAccount = () => {
  const [activeTab, setActiveTab] = useState(1)
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null)
  const navigate = useNavigate();

  useEffect(() => {
    async function getEventsAndUser() {
      setIsLoading(true);
      try {
        const response = await axios.get(`${baseUrl}events/user/${store.user._id}`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          })
        const userResponse = await axios.get(`${baseUrl}users/${store.user._id}`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          })
        setIsLoading(false)
        if (!response.data) {
          setResponseMessage({ ok: true, message: "You have not uploaded any event posters" })
        }
        store.updateEvents(response.data);
        if(userResponse.data){
          store.updateUser(userResponse.data)
        }
      } catch (error) {
        setIsLoading(false)
        setResponseMessage({ ok: false, message: 'Someting went wrong. Refresh the page.' })
      }
    }
    getEventsAndUser();

  }, [])
  if (!store.user) {
    return navigate('/signin')
  }

  return (
    <div className='container full-height'>
      {(store.user.status !== "Active") ?
        (<div className="alert alert-warning">
          <span className='fw-bold'>Pending Account!</span> Please check your email for a verification message. If you don't find it, kindly check your spam folder.
        </div>) : null
      }
      <Row className='mb-5'>
        <Col className='text-start' xs={12} sm={6}>
          <h2 className='display-4 fw-bold'>Hi {store.user.firstName}!</h2>
          <p>Welcome to your event posters dashboard!</p>
        </Col>
        <Col xs={12} sm={6} className='text-start'>
          <div className='my-5'>
            <Button outline={store.darkTheme ? true : false} color={store.darkTheme ? "light" : "dark"} size="lg" className='px-5 py-3' onClick={() => navigate(`/create-event-dp`)}>Upload New Poster</Button>
          </div>
        </Col>
        <hr className='my-1'></hr>
      </Row>


      {responseMessage ? (responseMessage.ok ?
        (<div className="alert alert-success">
          {responseMessage.message}
        </div>) :
        (<div className="alert alert-danger">
          <strong>Error!</strong> {responseMessage.message}
        </div>)) : null
      }
      <Media queries={{ small: { maxWidth: 576 } }}>
        {matches => {
          return (
            <div className='row profile-main'>
              <Col className='profile-nav mb-4' sm={3}>
                <Nav fill pills vertical={matches.small ? false : true} style={{ flexWrap: matches.small ? "nowrap" : "wrap" }}>
                  <NavItem>
                    <NavLink href="#"
                      className={activeTab === 1 ? "active profile-links" : "profile-links-light light-theme"}
                      onClick={() => setActiveTab(1)}
                    >
                      Dashboard
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#"
                      className={activeTab === 2 ? "active profile-links" : "profile-links-light light-theme"}
                      onClick={() => setActiveTab(2)}
                    >
                      Posters
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#"
                      className={activeTab === 3 ? "active profile-links" : "profile-links-light light-theme"}
                      onClick={() => setActiveTab(3)}
                    >
                      Settings
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col className='profile-details' sm={9}>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={1}>
                    <Dashboard />
                  </TabPane>
                  <TabPane tabId={2}>
                    <Events />
                  </TabPane>
                  <TabPane tabId={3}>
                    <Profile />
                  </TabPane>
                </TabContent>

              </Col>
            </div>
          )
        }

        }
      </Media>
    </div>
  )
}

export default MyAccount