import { useFormikContext } from 'formik';
import store from '../../../shared/store';
import { useEffect } from 'react';

const Coordinates = ({imageLoaded, divRef}) => {
    const { values, setFieldValue } = useFormikContext();

    const handleWidthChange = (event) => {
        const value = parseInt(event.target.value);
        if (!isNaN(value)) {
            setFieldValue('width', value);
        }
    };

    const handleHeightChange = (event) => {
        const value = parseInt(event.target.value);
        if (!isNaN(value)) {
            setFieldValue('height', value);
        }
    };

    const handleBorderRadiusChange = (event) => {
        const value = event.target.value;
        setFieldValue('borderRadius', value);
    };

    useEffect(()=>{
        if (divRef.current) {
            const { width, height } = divRef.current.getBoundingClientRect();
            // console.log(width, height)
            setFieldValue('contWidth', width);
            setFieldValue('contHeight', height);
        }
    },[imageLoaded])

    return (
        <div className='col'>
            <h4 className='cutout-title'>Cutout Area Settings</h4>
            <p>Select the cutout area on the uploaded image, drag and resize as desired or adjust by changing the values below:</p>
            <div>
                <div>
                    <label htmlFor="widthInput" className='lbl-title'>Width: </label>
                    <input
                        className={store.darkTheme ? 'input dark-theme': 'input light-theme'}
                        id="widthInput"
                        type="number"
                        min="0"
                        value={values.width}
                        onChange={handleWidthChange}
                    />
                </div>
                <div>
                    <label htmlFor="heightInput" className='lbl-title'>Height: </label>
                    <input
                        className={store.darkTheme ? 'input dark-theme': 'input light-theme'}
                        id="heightInput"
                        type="number"
                        min="0"
                        value={values.height}
                        onChange={handleHeightChange}
                    />
                </div>
                <div>
                    <label htmlFor="borderRadiusInput" className='lbl-title'>Border Radius (increase value to get rounded edges): </label>
                    <input
                        className={store.darkTheme ? 'input dark-theme': 'input light-theme'}
                        id="borderRadiusInput"
                        type="number"
                        min="0"
                        max='50'
                        value={values.borderRadius}
                        onChange={handleBorderRadiusChange}
                    />
                </div>
            </div>
        </div>

    );
};

export default Coordinates;
