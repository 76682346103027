import React from 'react';
import ColorPicker from "../../images/ColorPicker.png"
import Fileupload from "../../images/Fileupload.png"
import FrameArt02 from "../../images/FrameArt02.png"
import Photo from "../../images/Photo.png"
import { Col, Row } from 'reactstrap';

const Features = () => {
    return (
        <section className='features-works'>
            {/* HOW IT WORKS */}
            <section className="how-it-works">
                <h3>How it works</h3>
                <p>Create your customised event posters in simple easy steps.</p>
            </section>
            <Row className="upload-poster">

                <Col md={6}>
                    <h5>1. Upload event <span className="blurry">poster</span></h5>
                    <p>
                        Complete the "create event poster" form by providing the necessary details of your event and uploading the event poster.
                    </p>
                </Col>
                <Col className="upload-img" md={{ offset: 1, size: 5 }}>
                    <img src={Photo} alt="Photoimage" />
                </Col>
            </Row>
            <Row className="upload-poster">

                <Col md={6}>
                    <h5>2. Select poster <span className="blurry">cut-out</span></h5>
                    <p>
                    Once the poster is uploaded to the canvas, drag and resize the cut-out area to the desired space where participants can upload their pictures, and then save the changes.
                    </p>
                </Col>
                <Col className="upload-img" md={{ offset: 1, size: 5 }}>
                    <img src={ColorPicker} alt="ColorPicker" />
                </Col>
            </Row>
            <Row className="upload-poster">

                <Col md={6}>
                    <h5>3. Activate and share <span className="blurry">poster</span> link</h5>
                    <p>
                    Checkout and select the desired number of months for the link's active duration. Afterward, proceed to make the payment accordingly. Upon successful payment, a shareable link will be generated, allowing you to share it with participants.
                    </p>
                </Col>
                <Col className="upload-img" md={{ offset: 1, size: 5 }}>
                    <img src={Fileupload} alt="Fileupload" />
                </Col>
            </Row>
            <Row className="upload-poster">

                <Col md={6}>
                    <h5>4. Participants <span className="blurry">upload</span> picture and <span className="blurry">download</span> poster</h5>
                    <p>
                    Participants can access the link, where they will be able to upload their pictures into the designated cut-out area. Subsequently, they can download the personalized poster containing their uploaded picture.
                    </p>
                </Col>
                <Col className="upload-img" md={{ offset: 1, size: 5 }}>
                    <img src={FrameArt02} alt="FrameArt02" />
                </Col>
            </Row>
        </section>
    )
}

export default Features;